import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { UseFormReturn } from "react-hook-form";
import { userFormValidation } from "utilities/userFormValidation";
import styles from "./ShippingDetails.module.scss";
import { DeliveryOption } from "utilities/constants/enums";
import { DELIVERY_TIME } from "utilities/constants/constants";

interface Props {
	formProps: UseFormReturn;
	xs?: number;
	required?: boolean;
	isOnboarding?: boolean;
	disabled?: boolean;
}

const ShippingDetails = ({
	formProps: {
		register,
		watch,
		formState: { errors },
	},
	xs = 4,
	required = true,
	isOnboarding = false,
	disabled = false,
}: Props) => {
	return (
		<Grid
			container
			item
			lg={xs}
			md={12}
			className={styles.shippingDetailsContainer}
			justifyContent="flex-start"
		>
			<Grid item xs={12}>
				<InputLabel>
					<Typography variant="body1" display="inline">
						Street address
					</Typography>
					{isOnboarding && (
						<>
							{watch("deliveryOption") === DeliveryOption.B2C ? (
								<Typography
									variant="caption"
									color="text.secondary"
									display="inline"
								>
									{" "}
									(Received at <b>home address</b> between {DELIVERY_TIME.HOME})
								</Typography>
							) : (
								<Typography
									variant="caption"
									color="text.secondary"
									display="inline"
								>
									{" "}
									(Received at <b>office address</b> between{" "}
									{DELIVERY_TIME.OFFICE})
								</Typography>
							)}{" "}
						</>
					)}
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					InputProps={{
						classes: {
							root: disabled ? styles.disabled : "",
							input: styles.formTextInput,
						},
					}}
					error={!!errors.streetAddress}
					helperText={userFormValidation.streetAddress.getHelperText(errors)}
					{...register(
						"streetAddress",
						userFormValidation.streetAddress.getValidationProps(required),
					)}
				/>
			</Grid>
			<Grid item xs={2.75} mr={3}>
				<InputLabel>
					<Typography variant="body1">Postal code</Typography>
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					error={!!errors.postalCode}
					InputProps={{
						classes: {
							root: disabled ? styles.disabled : "",
							input: styles.formTextInput,
						},
					}}
					helperText={userFormValidation.postalCode.getHelperText(errors)}
					{...register(
						"postalCode",
						userFormValidation.postalCode.getValidationProps(required),
					)}
				/>
			</Grid>
			<Grid item xs={2.75} mr={3}>
				<InputLabel>
					<Typography variant="body1">City</Typography>
				</InputLabel>
				<TextField
					fullWidth
					size="small"
					InputProps={{
						classes: {
							root: disabled ? styles.disabled : "",
							input: styles.formTextInput,
						},
					}}
					error={!!errors.city}
					helperText={userFormValidation.city.getHelperText(errors)}
					{...register("city", userFormValidation.city.getValidationProps(required))}
				/>
			</Grid>
		</Grid>
	);
};

export { ShippingDetails };
