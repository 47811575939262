import { FieldValues, useForm } from "react-hook-form";
import { Grid, InputLabel, TextField, Typography } from "@mui/material";
import { PersonalDetails } from "./PersonalDetails";
import { ShippingDetails } from "./ShippingDetails/ShippingDetails";
import { useEffect } from "react";
import { useAppSelector } from "hooks/hooks";
import { usersSelectors } from "features/users";
import { Employee, FormEmployee, User } from "types";
import styles from "./UserDetails.module.scss";
import { selectCustomerDetails } from "features/customer";
import { DeliveryOptions } from "components/Hardware/HardwareCheckout/DeliveryOptions";
import { DeliveryOption } from "utilities/constants/enums";
import { mailDomainsSelectors } from "features/mailDomains";

interface Props {
	employeeData: Employee & User & { companyName?: string };
	saveForm: (newData: any) => void;
	enableSaveButton?: () => void;
	setIsFormDataDirty?: (isDirty: boolean) => void;
	showShippingDetails?: boolean;
	isShippingAddressRequired?: boolean;
	isOnboarding?: boolean;
}

const UserDetails = ({
	employeeData,
	employeeData: {
		mailPrefix,
		mailSuffix,
		mail,
		givenName,
		surname,
		password,
		officeLocation,
		department,
		privateEmail,
		mobilePhone,
		country,
		streetAddress,
		postalCode,
		city,
		userPrincipalName,
		manager: managerId,
		hardwareNote,
		companyName,
	},
	saveForm,
	setIsFormDataDirty = () => {},
	showShippingDetails = true,
	isShippingAddressRequired = true,
	enableSaveButton = () => {},
	isOnboarding = true,
}: Props) => {
	const customerInfo = useAppSelector(selectCustomerDetails);
	const getMailPrefix = () => {
		if (mailPrefix?.length > 0) {
			return mailPrefix;
		}
		const mailAddress = mail || userPrincipalName || "";
		return mailAddress.substring(0, mailAddress.indexOf("@"));
	};

	const getMailSuffix = () => {
		if (mailSuffix?.length > 0) {
			return mailSuffix;
		}
		const mailAddress = mail || userPrincipalName || "";
		return mailAddress.substring(mailAddress.indexOf("@") + 1);
	};

	const manager =
		useAppSelector((state) => usersSelectors.selectById(state, managerId)) ?? ({} as User);

	const suffix = getMailSuffix();
	const defaultValues = {
		mailPrefix: getMailPrefix(),
		mailSuffix: suffix,
		givenName,
		surname,
		password,
		officeLocation,
		department,
		manager,
		country,
		privateEmail,
		mobilePhone,
		streetAddress,
		postalCode,
		city,
		hardwareNote,
		deliveryOption: DeliveryOption.B2B,
	} as FormEmployee;

	const formProps = useForm<FieldValues>({
		mode: "onSubmit",
		defaultValues,
	});

	const {
		register,
		handleSubmit,
		watch,
		formState: { isDirty },
	} = formProps;

	useEffect(() => {
		setIsFormDataDirty(isDirty);
	}, [isDirty, setIsFormDataDirty]);

	// We need to ensure that the current mail suffix is also included in the available mail domain dropdown.
	// To avoid any issue where the user is not able to get the same mail address as before.
	const availableMailDomains = [...useAppSelector(mailDomainsSelectors.selectAll)];
	if (!availableMailDomains.some((domain) => domain.name === suffix)) {
		availableMailDomains.push({ name: suffix, id: 9876543210 });
	}

	return (
		<form onSubmit={handleSubmit(saveForm)} id="personal-data-form" onChange={enableSaveButton}>
			<Grid container className={styles.container}>
				<PersonalDetails
					formProps={formProps}
					employeeData={{ ...employeeData, manager }}
					availableMailDomains={availableMailDomains}
				/>
				{showShippingDetails && (
					<Grid className={styles.shippingDetailsContainer}>
						<Typography variant="body1" fontWeight={500}>
							Address
						</Typography>
						{isOnboarding && (
							<>
								<Grid item py={1}>
									<DeliveryOptions control={formProps.control} />
								</Grid>
								{watch("deliveryOption") === DeliveryOption.B2B && (
									<Grid item>
										<InputLabel>
											<Typography variant="body1" display="inline">
												Company name
											</Typography>
										</InputLabel>
										<TextField
											fullWidth
											defaultValue={customerInfo?.departmentName ?? ""}
											value={companyName}
											size="small"
											InputProps={{
												classes: {
													input: styles.formTextInput,
												},
											}}
											{...register("companyName")}
										/>
									</Grid>
								)}
							</>
						)}
						<Grid item mt={isOnboarding ? 0 : 1.8}>
							<ShippingDetails
								required={isShippingAddressRequired}
								isOnboarding={isOnboarding}
								formProps={formProps}
								xs={12}
							/>
						</Grid>
						{isOnboarding && (
							<Grid item xs={10}>
								<InputLabel>
									<Typography variant="body1" display="inline" mr={1}>
										Additional information
									</Typography>
									<Typography
										variant="body1"
										color="text.secondary"
										display="inline"
									>
										(optional)
									</Typography>
								</InputLabel>
								<TextField
									multiline
									placeholder="Notes will be shown inside the order history table"
									rows={3}
									fullWidth
									size="small"
									InputProps={{
										classes: {
											input: styles.formTextInput,
										},
									}}
									{...register("hardwareNote")}
								/>
							</Grid>
						)}
					</Grid>
				)}
			</Grid>
		</form>
	);
};

export { UserDetails };
