export enum DeviceOwnership {
	Company = "Company",
	Personal = "Personal",
}

export enum ManagementType {
	Mdm = "MDM",
}

export enum Manufacturer {
	DellInc = "Dell Inc.",
	Lenovo = "LENOVO",
	MicrosoftCorporation = "Microsoft Corporation",
	Nutanix = "Nutanix",
}

export enum OperatingSystem {
	Android = "Android",
	IOS = "iOS",
	Unknown = "Unknown",
	Windows = "Windows",
}

export enum ProductDeliveryStatus {
	ACCEPTED = "Accepted",
	SHIPPED = "Shipped",
	INVOICED = "Invoiced",
}

export enum TrustType {
	AzureAd = "AzureAd",
	ServerAd = "ServerAd",
	Workplace = "Workplace",
}

export enum DKIMEnum {
	Configured = "Configured",
	NotCorrectlyConfiguredForOffice365 = "Not correctly configured for Office 365",
}

export enum Dmarc {
	NotEnabledMissingPAction = "Not enabled (missing p=action)",
	PartiallyPNone = "Partially (p=none)",
}

export enum SPF {
	Configured = "Configured",
	NotConfigured = "Not Configured",
}

export enum LastSeenEnum {
	NotFound = "Not found",
}

export enum StatusEnum {
	Enabled = "Enabled",
	LockedOut = "LockedOut",
}

export enum UserType {
	Guest = "Guest",
	Member = "Member",
	ExternalMember = "External Member",
}

export enum UserAuthenticationMethod {
	None = "None",
	Fido = "Fido",
	WindowsHelloForBusiness = "Windows Hello For Business",
	MicrosoftAuthenticatorPasswordless = "Microsoft Authenticator Passwordless",
	MicrosoftAuthenticatorPush = "Microsoft Authenticator Push",
	AppCode = "App Code",
	AppNotification = "App Notification",
	AlternateMobilePhone = "Alternate Mobile Phone",
	MobilePhone = "Mobile Phone",
	OfficePhone = "Office Phone",
	Email = "Email",
	AppPassword = "App Password",
	SoftwareOneTimePasscode = "Software One Time Passcode",
}

export enum UserActivityType {
	Active = "Active",
	Inactive = "Inactive",
	NeverSignedIn = "Never Signed In",
	AccountDisabled = "Account Disabled",
	NewUser = "New User",
}

export enum SortBy {
	NotSorted = "notSorted",
	Availability = "availability",
	DiscountAmount = "discountAmount",
	DiscountPercentage = "discountPercentage",
	NameAscending = "nameAscending",
	NameDescending = "nameDescending",
	PriceLowToHigh = "priceLowToHigh",
	PriceHighToLow = "priceHighToLow",
}

export enum RiskEventType {
	unlikelyTravel = "unlikelyTravel",
	newCountry = "newCountry",
	impossibleTravel = "impossibleTravel",
	anonymizedIPAddress = "anonymizedIPAddress",
	maliciousIPAddress = "maliciousIPAddress",
	unfamiliarFeatures = "unfamiliarFeatures",
	malwareInfectedIPAddress = "malwareInfectedIPAddress",
	suspiciousIPAddress = "suspiciousIPAddress",
	leakedCredentials = "leakedCredentials",
	investigationsThreatIntelligence = "investigationsThreatIntelligence",
	generic = "generic",
	adminConfirmedUserCompromised = "adminConfirmedUserCompromised",
	passwordSpray = "passwordSpray",
	anomalousToken = "anomalousToken",
	tokenIssuerAnomaly = "tokenIssuerAnomaly",
	suspiciousBrowser = "suspiciousBrowser",
	riskyIPAddress = "riskyIPAddress",
	mcasSuspiciousInboxManipulationRules = "mcasSuspiciousInboxManipulationRules",
	suspiciousInboxForwarding = "suspiciousInboxForwarding",
	mcasImpossibleTravel = "mcasImpossibleTravel",
}

export enum ConfiguratorUserTypes {
	Approvers = "approvers",
	Creators = "creators",
}

export enum OnboardingStatus {
	Initial = 0,
	InProgress = 1,
	Complete = 2,
	Failed = 3,
}

export enum HotjarEventTypes {
	CLICK = "click",
	SUBMIT = "submit",
	SHOW = "show",
	OPEN = "open",
	CLOSE = "close",
}

export enum CostType {
	YOUR_EMPLOYEES = "YOUR_EMPLOYEES",
	YOUR_IT_SYSTEMS = "YOUR_IT_SYSTEMS",
	AZURE = "AZURE",
	OFFICE_365 = "OFFICE_365",
	CONSULTANCY = "CONSULTANCY",
	RESERVED_INSTANCES = "RESERVED_INSTANCES",
	MARKETPLACE_ITEMS = "MARKETPLACE_ITEMS",
}

export enum Currency {
	Nok = "NOK",
	Sek = "SEK",
}

export enum TermDuration {
	P1M = "P1M", // monthly / 1 month
	P1Y = "P1Y", // annual / yearly / 12 months
}

export enum CostSavingsType {
	RemoveUnused = "REMOVE_UNUSED",
	TermDurationChange = "CHANGE_TERMDURATIONRATIO",
	TotalSavings = "TOTAL_SAVINGS",
}

export enum LicenseActionOperationType {
	AdjustLicenseCount = "Adjust LicenseCount",
	SoftDeleteUser = "Soft Delete User",
	AutorenewOff = "Turn off AutoRenew",
	AutorenewOn = "Turn on AutoRenew",
	CancelSubscription = "Cancel Subscription",
	SuspendSubscription = "Suspend Subscription",
	CreateNewSubscription = "Create Subscription",
}

export enum MailType {
	SoftDeleteUsersAction = "SOFT_DELETE_USERS_ACTION",
	AdjustLicensesAction = "ADJUST_LICENSES_ACTION",
	RemoveLicensesAction = "REMOVE_LICENSES_ACTION",
	BuyLicensesAction = "BUY_LICENSES_ACTION",
	LicenseAssignmentAction = "LICENSE_ASSIGNMENT_ACTION",
	NewUserOnboarding = "ONBOARDING",
}

export enum TicketAgreement {
	CSP = "CSP",
	YourEmployees = "Your Employees",
	YourITSystems = "Your IT Systems",
	Consulting = "Consulting",
	FirstLineSupport = "1st Line Support",
}

export enum LocationEnum {
	EuNorth = "eu north",
	EuWest = "eu west",
	Intercontinental = "intercontinental",
	NoEast = "no east",
	Unknown = "unknown",
	UsEast = "us east",
	UsEast2 = "us east 2",
	UsNorthCentral = "us north central",
	UsWest = "us west",
	UsWest2 = "us west 2",
}

export enum DATE_FORMATS {
	FULL = "DD.MM.YYYY HH:mm",
	NO_CLOCK = "DD.MM.YYYY",
	SHORT = "MM-DD",
}

export enum ROLES {
	USER = "USER",
}

export enum RISK_LEVELS {
	LOW = "low",
	MEDIUM = "medium",
	HIGH = "high",
}

export enum RISK_TYPES {
	low = "No risk detected",
	medium = "Risk level Medium:",
	high = "Risk level High:",
}

export enum BILLING_TYPE {
	AZURE = "azure",
	OFFICE365 = "office365",
}

export enum COLAB_GROUPING_TYPE {
	USERS = "Users",
	GROUPS = "Groups",
}

export enum COLAB_ROLE_TYPE {
	GROUP = "Group",
	ROLE = "Role",
	USER = "User",
}

export enum CARD_STATUS_COLOR {
	GREEN = "green",
	RED = "red",
	ORANGE = "orange",
}

export enum COLLAB_GROUPING_TYPE {
	USERS = "Users",
	GROUPS = "Groups",
}

export enum COLLAB_ROLE_TYPE {
	GROUP = "Group",
	ROLE = "Role",
	USER = "User",
}

export const enum ApiRefetchStatus {
	WAITING = "Waiting",
	SUCCESS = "Success",
}

export const enum ActionLoadingStatus {
	NOT_STARTED = "NotStarted",
	IN_PROGRESS = "InProgress",
	COMPLETED = "Completed",
	FAILED = "Failed",
}

export const enum DataGenerationTypes {
	office365Cost = "office365Cost",
	azureCost = "azureCost",
	azureReservedInstancesCost = "azureReservedInstancesCost",
	azureMarketplaceItemsCost = "azureMarketplaceItemsCost",
	aggregatedData = "aggregatedData",
}

export enum LicenseActionStatus {
	Scheduled = "Scheduled",
	Finished = "Finished",
	Error = "Error",
	Cancelled = "Cancelled",
	InProgress = "InProgress",
	PartiallyFinished = "PartiallyFinished",
}

export enum LicenseActionTargetType {
	Subscription = "Subscription",
	User = "User",
}

export enum HardwareDrawerType {
	Cart = "Cart",
	Bundle = "Bundle",
}

export enum HardwarePurchaseType {
	Lease = "Lease",
	Buy = "Buy",
}

export enum SwapType {
	SwapWithCartProduct = "SwapWithCartProduct",
	SwapWithBundleProduct = "SwapWithBundleProduct",
	AddToBundle = "AddToBundle", // These swapTypes do not really swap any item that's already in the cart/bundle, but rather adds a new item to the cart/bundle
	AddToCart = "AddToCart",
}

export enum DeliveryOption {
	B2B = "B2B",
	B2C = "B2C",
}

export enum HardwareDeliveryCountryOption {
	Any = "Any",
	Norway = "Norway",
	Sweden = "Sweden",
	None = "None",
}

export enum LicenseAdjustmentDrawerType {
	BUY_LICENSES = "BUY_LICENSES",
	REMOVE_LICENSES = "REMOVE_LICENSES",
}

export enum DownloadFileStatusEnum {
	NotInteractedOn,
	Downloading,
	Downloaded,
	Error,
}

export enum LicenseAssignmentType {
	Direct = "Direct",
	Group = "Group",
}

export enum LicenseAssignmentState {
	Active = "Active",
	ActiveWithError = "ActiveWithError",
	Disabled = "Disabled",
	Error = "Error",
}

export enum CapabilityStatus {
	Enabled = "Enabled",
	Warning = "Warning",
	Suspended = "Suspended",
	Deleted = "Deleted",
	LockedOut = "LockedOut",
}

export enum DocumentType {
	Invoice = "Invoice",
	Agreement = "Agreements",
	IncidentReport = "Incident Reports",
	OperationalMeetings = "Operational Meetings",
}

export enum IntuneManagementType {
	IronstonePolicyManaged = "Ironstone policy",
	CustomerPolicyManaged = "Customer policy",
	NoPolicy = "No policy",
}

export enum DeviceComplianceStatus {
	Compliant = "Compliant",
	NonCompliant = "Non-compliant",
	Inactive = "Inactive",
}

export enum DeviceFilterKey {
	OS = "osFilters",
	INTUNE = "intuneManagementFilters",
	COMPLIANCE = "complianceFilters",
}

export enum EntraStatus {
	Joined = "Joined",
	Registered = "Registered",
}

export const ComplianceSettingsEnum = {
	NoPolicyAssigned: "DefaultDeviceCompliancePolicyRequireDeviceCompliancePolicyAssigned",
	InactiveDevice: "DefaultDeviceCompliancePolicyRequireRemainContact",
	UserAccountDeleted: "DefaultDeviceCompliancePolicyRequireUserExistence",
	TPMRequired: "Windows10CompliancePolicyTpmRequired",
	BitLockerEnabled: "Windows10CompliancePolicyBitLockerEnabled",
	StorageRequiresEncryption: "Windows10CompliancePolicyStorageRequireEncryption",
	SecureBootEnabled: "Windows10CompliancePolicySecureBootEnabled",
	AntivirusRequired: "Windows10CompliancePolicyAntivirusRequired",
	DefenderEnabled: "Windows10CompliancePolicyDefenderEnabled",
	RealTimeProtectionEnabled: "Windows10CompliancePolicyRtpEnabled",
	SignatureOutOfDate: "Windows10CompliancePolicySignatureOutOfDate",
	OSMinimumVersionRequired: "Windows10CompliancePolicyOsMinimumVersion",
	ActiveFirewallRequired: "Windows10CompliancePolicyActiveFirewallRequired",
};

export enum ApproverType {
	Manager = "MANAGER",
	Global = "GLOBAL",
	None = "NONE",
}

export enum Role {
	ADMIN = "admin",
	PORTALREAD = "portalread",
	USER = "user",
	FINANCE = "finance",
	HR = "hr",
}

export enum LicenseAppliesTo {
	User = "USER",
	Company = "COMPANY",
}

export enum BillingCycle {
	Monthly = "MONTHLY",
	Annual = "ANNUAL",
}
