import { useState } from "react";
import { DIRECTORY_SYNCHRONIZATION_ACCOUNT } from "utilities/constants/constants";
import { RISK_LEVELS, UserActivityType } from "utilities/constants/enums";
import { NAVIGATION_NAMES } from "utilities/constants/pages.tsx";
import securityDashboardSettings from "settings/securityDashboard.json";
import { Grid } from "@mui/material";
import { InfoBadge } from "../InfoBadge";
import { RiskNotification } from "./RiskNotification";
import { SignIns } from "./SignIns";
import { MFAStatistics } from "./MFAStatistics";
import { DevicesStatistics } from "./DevicesStatistics";
import { AdminsWithoutMFADialog } from "./AdminsWithoutMFADialog";
import { DetailedRisksDialog } from "./DetailedRisks/DetailedRisksDialog/DetailedRisksDialog";
import { EnterpriseAppsDialog } from "./EnterpriseAppsDialog";

import styles from "./SecurityDashboard.module.scss";

import { fetchAggregatedData } from "actions/aggregatedDataActions";
import { useApiOnce, useAppSelector, useAuth } from "hooks/hooks";
import { selectAggregatedData } from "features/aggregatedData";
import { useAppNavigate } from "hooks/useAppNavigate";
import { InternalLinkContent } from "components/Common/Links";
import { fetchUsers } from "actions/userActions";
import { selectUsers, setUserActivityFilter } from "features/users";
import { selectCustomerDetails } from "features/customer";
import clsx from "clsx";

const SecurityDashboard = () => {
	const [settings] = useState({ ...securityDashboardSettings });
	const [isMFAUsersDialogOpen, setIsMFAAdminsDialogOpen] = useState(false);
	const [isEnterpriseAppsDialogOpen, setIsEnterpriseAppsDialogOpen] = useState(false);
	const [isDetailedRisksDialogOpen, setIsDetailedRisksDialogOpen] = useState(false);

	const { isLoading, isFetching, ...aggregatedData } = useAppSelector(selectAggregatedData);

	useApiOnce(fetchAggregatedData, { isLoading, isFetching });
	useApiOnce(fetchUsers, useAppSelector(selectUsers));

	const adminsWithoutMFA =
		aggregatedData.Check_DirectoryRole?.CheckValues?.AllRolesDetailed?.flatMap((role) => {
			return role.Role !== DIRECTORY_SYNCHRONIZATION_ACCOUNT
				? role?.MembersWithoutMFA.map((user) => {
						return {
							role: role.Role,
							...user,
						};
				  })
				: [];
		});

	const numberOfAdminsWithoutMFA = adminsWithoutMFA ? adminsWithoutMFA.length : 0;

	const { navigateToPage } = useAppNavigate();
	const { dispatch } = useAuth();

	const handleNavigateToManageEmployees = () => {
		if (isLoading) return;
		dispatch(
			setUserActivityFilter([UserActivityType.Inactive, UserActivityType.NeverSignedIn]),
		);
		navigateToPage(NAVIGATION_NAMES.MY_PEOPLE.path);
	};

	const customerInfo = useAppSelector(selectCustomerDetails);

	return (
		<Grid container className={styles.wrapper}>
			<Grid container className={styles.container}>
				<Grid container spacing={2} className={styles.paper}>
					<Grid container item spacing={2}>
						<Grid container xs={8} item spacing={2} className={styles.cards}>
							<Grid item xs onClick={handleNavigateToManageEmployees} role="link">
								<InfoBadge
									isLoading={isLoading}
									label="Inactive users"
									secondaryLabel={"Users not active last 90 days"}
									level={
										aggregatedData.Check_LastSignIn?.CheckValues
											?.UsersNotSignedInLast90Days > 0
											? RISK_LEVELS.MEDIUM
											: RISK_LEVELS.LOW
									}
									currentCount={
										aggregatedData.Check_LastSignIn?.CheckValues
											?.UsersNotSignedInLast90Days
									}
									ofTotalText="Users"
									link={
										<InternalLinkContent
											className={clsx(styles.link, {
												[styles.hide]: !customerInfo?.yourEmployeesEnabled,
											})}
										>
											Show inactive users
										</InternalLinkContent>
									}
								/>
							</Grid>
							<Grid
								item
								xs
								onClick={() => !isLoading && setIsEnterpriseAppsDialogOpen(true)}
							>
								<InfoBadge
									isLoading={isLoading}
									label="Enterprise apps"
									secondaryLabel={"Added last two weeks"}
									level={
										aggregatedData.Check_ServicePrincipals?.CheckValues
											.SPs_CreatedLast14Days.length > 0
											? RISK_LEVELS.MEDIUM
											: RISK_LEVELS.LOW
									}
									currentCount={
										aggregatedData.Check_ServicePrincipals?.CheckValues
											.SPs_CreatedLast14Days.length
									}
									ofTotalText="New apps"
									link={
										<InternalLinkContent>
											View added enterprise apps
										</InternalLinkContent>
									}
								/>
							</Grid>
							<Grid
								item
								xs
								onClick={() => !isLoading && setIsMFAAdminsDialogOpen(true)}
							>
								<InfoBadge
									isLoading={isLoading}
									label="Admins without MFA"
									secondaryLabel={"Active admins"}
									level={
										numberOfAdminsWithoutMFA > 0
											? RISK_LEVELS.HIGH
											: RISK_LEVELS.LOW
									}
									currentCount={numberOfAdminsWithoutMFA}
									ofTotalText="Admins"
									link={<InternalLinkContent>View admins</InternalLinkContent>}
								/>
							</Grid>
						</Grid>
						<Grid container xs={4} item spacing={2} className={styles.riskySignIns}>
							<Grid
								item
								xs
								onClick={() => !isLoading && setIsDetailedRisksDialogOpen(true)}
							>
								<RiskNotification
									isLoading={isLoading}
									highRiskCount={
										aggregatedData.Check_RiskDetections?.CheckValues.ActiveRisks
											?.High_Risk
									}
									mediumRiskCount={
										aggregatedData.Check_RiskDetections?.CheckValues.ActiveRisks
											?.Medium_Risk
									}
									lowRiskCount={
										aggregatedData.Check_RiskDetections?.CheckValues.ActiveRisks
											?.Low_Risk
									}
									hiddenRiskCount={
										aggregatedData.Check_RiskDetections?.CheckValues.ActiveRisks
											?.Hidden_Risk
									}
									onViewDetails={() =>
										!isLoading && setIsDetailedRisksDialogOpen(true)
									}
									title={"Risky sign ins"}
									severityType="risk"
									linkLabelText="View risks details"
								/>
							</Grid>
						</Grid>
					</Grid>
					<Grid
						container
						direction="column"
						item
						xs={8}
						spacing={2}
						className={styles.signInsDeviceStatisticsWrapper}
					>
						<Grid item xs={6}>
							<SignIns
								isLoading={isLoading}
								settings={settings.signIns}
								checkSignInsData={aggregatedData.Check_Signins?.CheckValues}
								chartStyles={styles.signInChart}
							/>
						</Grid>
						<Grid item xs={6}>
							<DevicesStatistics
								isLoading={isLoading}
								settings={settings.deviceCompliancy}
								checkDevicesValues={aggregatedData.Check_Devices?.CheckValues}
								chartStyles={styles.deviceChart}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						item
						xs={4}
						direction="column"
						className={styles.mfaStatisticsWrapper}
					>
						<Grid item xs={12}>
							<MFAStatistics
								isLoading={isLoading}
								settings={settings.MFAStats}
								checkCredentialRegistrationValues={
									aggregatedData.Check_CredentialRegistration?.CheckValues
								}
								chartStyles={styles.mfaChart}
							/>
						</Grid>
					</Grid>
				</Grid>
				{!isLoading && isMFAUsersDialogOpen && (
					<AdminsWithoutMFADialog
						onClose={() => setIsMFAAdminsDialogOpen(false)}
						admins={adminsWithoutMFA}
					/>
				)}
				{isDetailedRisksDialogOpen && (
					<DetailedRisksDialog
						onClose={() => setIsDetailedRisksDialogOpen(false)}
						Check_RiskDetections={
							aggregatedData.Check_RiskDetections?.CheckValues.RiskDetections ?? []
						}
					/>
				)}
				{isEnterpriseAppsDialogOpen && (
					<EnterpriseAppsDialog
						onClose={() => setIsEnterpriseAppsDialogOpen(false)}
						Check_ServicePrincipals={aggregatedData.Check_ServicePrincipals}
					/>
				)}
			</Grid>
		</Grid>
	);
};

export { SecurityDashboard };
