import { useEffect, useRef, useMemo } from "react";
import { Grid, Typography } from "@mui/material";
import { thickBottomBorder, marginOnGridLines } from "utilities/chartJsCustomPlugins";
import { MFALegendInfo } from "components/SecurityDashboard/ChartTypes/MFAChart/MFALegendInfo";

import Chart from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

import colors from "styles/utilities/_colors.scss";
import styles from "./MFAChart.module.scss";
import { InternalLink, InternalLinkContent } from "components/Common/Links";
import { useAppSelector, useAuth } from "hooks/hooks";
import { usersSelectors } from "features/users";
import { UserAuthenticationMethod } from "utilities/constants/enums";
import { downloadFilteredUserList } from "components/MyPeople/userUtils";

const MFAChart = ({
	isLoading,
	totalMembersWithMFA = 0,
	totalMembersWithoutMFA = 0,
	totalGuestsWithMFA = 0,
	totalGuestsWithoutMFA = 0,
	usersWithoutMfaDetails = [],
	settings = {},
	chartStyles,
}) => {
	const { dispatch } = useAuth();
	const canvasRef = useRef(null);
	const totalSum =
		totalMembersWithMFA + totalMembersWithoutMFA + totalGuestsWithMFA + totalGuestsWithoutMFA;

	const legendItems = useMemo(
		() => [
			{
				labelText: "Members",
				with: totalMembersWithMFA,
				without: totalMembersWithoutMFA,
			},
			{
				labelText: "Guests",
				with: totalGuestsWithMFA,
				without: totalGuestsWithoutMFA,
			},
		],
		[totalMembersWithMFA, totalMembersWithoutMFA, totalGuestsWithMFA, totalGuestsWithoutMFA],
	);

	const membersWithoutMfaColor = colors.blueTint6;
	const membersWithMfaColor = colors.blueDark;
	const guestsWithoutMfaColor = colors.yellowTint4;
	const guestsWithMfaColor = colors.yellowTint2;

	useEffect(() => {
		const chart = new Chart(canvasRef.current.getContext("2d"), {
			plugins: [ChartDataLabels, thickBottomBorder, marginOnGridLines({ marginStart: 10 })],
			type: "bar",
			data: {
				labels: legendItems.map((legend) => legend.labelText),
				datasets: [
					{
						label: "With MFA",
						data: legendItems.map((legend) => legend.with),
						backgroundColor: legendItems.map((legend) => {
							if (isLoading) return colors.loading;
							if (legend.labelText === "Members") {
								return membersWithMfaColor;
							}
							return guestsWithMfaColor;
						}),
					},
					{
						label: "Without MFA",
						data: legendItems.map((legend) => legend.without),
						backgroundColor: legendItems.map((legend) => {
							if (isLoading) return colors.loading;
							if (legend.labelText === "Members") {
								return membersWithoutMfaColor;
							}
							return guestsWithoutMfaColor;
						}),
					},
				],
			},
			options: {
				animation: {
					duration: isLoading ? 0 : 1000,
				},
				maxBarThickness: 65,
				scales: {
					x: {
						stacked: true,
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
					},
					y: {
						stacked: true,
						grid: {
							display: false,
						},
						border: {
							display: false,
						},
						ticks: {
							display: !isLoading,
							maxTicksLimit: 12,
							callback: function (value, index) {
								// remove first and last tick
								if (index === 0 || index === this.ticks.length - 1) {
									return null;
								}

								return this.getLabelForValue(value);
							},
						},
					},
				},
				plugins: {
					htmlLegend: {
						containerID: "mfa-legend-container",
					},
					legend: {
						display: false,
					},
					datalabels: {
						display: false,
					},
					tooltip: {
						enabled: true,
					},
				},
				responsive: true,
				maintainAspectRatio: false,
			},
		});

		chart.canvas.id = "multifactorAuthentication";

		return () => chart.destroy();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [legendItems, settings, totalSum, isLoading]);

	const onClick = (event) => {
		const chart = Chart.getChart("multifactorAuthentication");
		const label = event.target.innerHTML;
		let x, y;
		if (label.includes("Members without MFA")) [x, y] = [1, 0];
		if (label.includes("Members with MFA")) [x, y] = [0, 0];
		if (label.includes("Guests without MFA")) [x, y] = [1, 1];
		if (label.includes("Guests with MFA")) [x, y] = [0, 1];
		event.currentTarget.classList.toggle(styles.strikethrough);
		const { data } = chart.data.datasets[x];
		data[y] = data[y] ? 0 : legendItems[y][x === 0 ? "with" : "without"];
		chart.update();
	};

	const allUsers = useAppSelector(usersSelectors.selectAll);
	const handleGoToMyEmployeesPage = () => {
		downloadFilteredUserList(dispatch, allUsers, [[], [], [UserAuthenticationMethod.None]]);
	};

	return (
		<>
			<Grid container spacing={2} className={styles.chartAndLegendContainer}>
				<Grid item>
					<div className={chartStyles}>
						<canvas ref={canvasRef} />
					</div>
				</Grid>
				<Grid container item xs={11} className={styles.legendContainer}>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography variant="body1">Members</Typography>
						</Grid>
						<Grid item xs={12}>
							<MFALegendInfo
								{...{ isLoading, onClick }}
								title={"Members without MFA"}
								color={membersWithoutMfaColor}
								value={totalMembersWithoutMFA}
							/>
						</Grid>
						<Grid item xs={12}>
							<MFALegendInfo
								{...{ isLoading, onClick }}
								title={"Members with MFA"}
								color={membersWithMfaColor}
								value={totalMembersWithMFA}
							/>
						</Grid>
					</Grid>
					<Grid container spacing={2} mt={1}>
						<Grid item xs={12}>
							<Typography variant="body1">Guests</Typography>
						</Grid>
						<Grid item xs={12}>
							<MFALegendInfo
								{...{ isLoading, onClick }}
								title={"Guests without MFA"}
								color={guestsWithoutMfaColor}
								value={totalGuestsWithoutMFA}
							/>
						</Grid>
						<Grid item xs={12}>
							<MFALegendInfo
								{...{ isLoading, onClick }}
								title={"Guests with MFA"}
								color={guestsWithMfaColor}
								value={totalGuestsWithMFA}
							/>
						</Grid>
					</Grid>
					<Grid item xs={12} pt={10}>
						<InternalLink onClick={handleGoToMyEmployeesPage}>
							<InternalLinkContent>Download users without MFA</InternalLinkContent>
						</InternalLink>
					</Grid>
				</Grid>
			</Grid>
		</>
	);
};

export { MFAChart };
