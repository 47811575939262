import {
	Grid,
	MenuItem,
	Select,
	ListSubheader,
	Typography,
	OutlinedInput,
	SelectChangeEvent,
} from "@mui/material";
import {
	IntuneManagementType,
	UserActivityType,
	UserAuthenticationMethod,
	UserType,
} from "utilities/constants/enums";
import clsx from "clsx";
import { PrimaryCheckbox } from "components/Common/Checkboxes";
import { AuthenticationStrengthGroup } from "types";

import styles from "./ListFilter.module.scss";

interface ListFilterProps {
	id: string;
	filterValues:
		| UserType[]
		| UserActivityType[]
		| string[]
		| IntuneManagementType[]
		| UserAuthenticationMethod[];
	filterOptions:
		| UserType[]
		| UserActivityType[]
		| string[]
		| IntuneManagementType[]
		| UserAuthenticationMethod[];
	icons?: { [key: string]: React.ReactElement } | undefined;
	updateFilter: (value: string[]) => void;
	noneSelectedLabel: string;
	disabled?: boolean;
}

export const ListFilter = ({
	id,
	filterValues,
	icons,
	filterOptions,
	updateFilter,
	noneSelectedLabel,
	disabled,
}: ListFilterProps) => {
	return (
		<Grid item className={clsx(styles.filterField, { [styles.disabled]: disabled })}>
			<Select
				id={id}
				multiple
				value={filterValues}
				size="small"
				displayEmpty
				className={clsx(styles.filterSelector, {
					[styles.filterActive]: filterValues.length !== 0,
				})}
				renderValue={(selected) => {
					if (selected.length === 0) {
						return noneSelectedLabel;
					}
					if (selected.length === filterOptions.length) {
						return noneSelectedLabel;
					}
					return selected.join(", ");
				}}
				onChange={(event) => updateFilter(event.target.value as string[])}
				placeholder="Select option"
			>
				{filterOptions.map((option, key) => (
					<MenuItem
						key={key}
						value={option}
						onClick={(event) => {
							const newFilterValues = [...filterValues];
							const index = newFilterValues.indexOf(option);
							if (index > -1) {
								newFilterValues.splice(index, 1);
							} else {
								newFilterValues.push(option);
							}
							updateFilter(newFilterValues);
							event.stopPropagation(); // Prevent the click from closing the dropdown
						}}
					>
						<PrimaryCheckbox
							label={""}
							checked={(filterValues as string[]).includes(option)}
							onChange={(e) => e.stopPropagation()} // Prevent the click from closing the dropdown
							size="small"
						/>
						{icons && icons[option] ? (
							<Grid ml={-2} mr={3}>
								{icons[option]}
							</Grid>
						) : null}
						<Typography ml={-2} variant="description">
							{option}
						</Typography>
					</MenuItem>
				))}
			</Select>
		</Grid>
	);
};

interface GroupedOption {
	label: UserAuthenticationMethod;
	groupName: string;
	strength: string;
	icon: React.ReactElement | null;
}

interface GroupedListFilterProps {
	id: string;
	filterValues: UserAuthenticationMethod[];
	icons: { [key: string]: React.ReactElement };
	groupedOptions: AuthenticationStrengthGroup[];
	updateFilter: (value: UserAuthenticationMethod[]) => void;
	noneSelectedLabel: string;
	disabled?: boolean;
}

export const GroupedListFilter = ({
	id,
	filterValues,
	icons,
	groupedOptions,
	updateFilter,
	noneSelectedLabel,
	disabled,
}: GroupedListFilterProps) => {
	// Flatten the grouped options into a single array with group info
	const options: GroupedOption[] = groupedOptions.flatMap((group) =>
		group.methods.map((method) => ({
			label: method,
			groupName: group.name,
			strength: group.strength,
			icon: icons[method] || null,
		})),
	);

	// Group options by groupName
	const groupedOptionsMap = groupedOptions.reduce((acc, group) => {
		acc[group.name] = group.methods.map((method) => ({
			label: method as UserAuthenticationMethod,
			strength: group.strength,
			icon: icons[method] || null,
			groupName: group.name,
		}));
		return acc;
	}, {} as { [key: string]: GroupedOption[] });

	// Handle select all in group
	const selectAllInGroup = (groupName: string) => {
		const groupMethods = options.filter((option) => option.groupName === groupName);
		const groupValues = groupMethods.map((option) => option.label);
		const allSelected = groupValues.every((value) => filterValues.includes(value));

		let newFilterValues = [...filterValues];
		if (allSelected) {
			// Deselect all in group
			newFilterValues = newFilterValues.filter((value) => !groupValues.includes(value));
		} else {
			// Select all in group
			groupValues.forEach((value) => {
				if (!newFilterValues.includes(value)) {
					newFilterValues.push(value);
				}
			});
		}
		updateFilter(newFilterValues);
	};

	// Handle select/deselect single option
	const handleOptionToggle = (value: UserAuthenticationMethod) => {
		const currentIndex = filterValues.indexOf(value);
		let newFilterValues = [...filterValues];

		if (currentIndex === -1) {
			newFilterValues.push(value);
		} else {
			newFilterValues.splice(currentIndex, 1);
		}

		updateFilter(newFilterValues);
	};

	return (
		<Grid item className={clsx(styles.filterField, { [styles.disabled]: disabled })}>
			<Select
				id={id}
				multiple
				displayEmpty
				value={filterValues}
				onChange={(event: SelectChangeEvent<UserAuthenticationMethod[]>) => {
					// We handle the selection manually, so this is left empty.
				}}
				input={<OutlinedInput />}
				renderValue={(selected) => {
					if (selected.length === 0 || selected.length === options.length) {
						return noneSelectedLabel;
					}
					return `${selected.length} selected`;
				}}
				disabled={disabled}
				className={clsx(styles.filterSelector, {
					[styles.filterActive]: filterValues.length !== 0,
				})}
				MenuProps={{
					PaperProps: {
						style: {
							maxHeight: 700,
							width: 375,
						},
					},
					disableAutoFocusItem: true,
					disablePortal: true,
				}}
			>
				{groupedOptions.map(({ name: groupName, strength }) => (
					<div key={groupName}>
						<ListSubheader className={styles.groupHeader} disableSticky>
							<Grid container className={styles.groupHeaderContent}>
								<Typography className={styles.groupHeaderName}>
									{groupName}
								</Typography>
								<Typography className={styles.groupHeaderStrength}>
									({strength})
								</Typography>
								{strength.toUpperCase().includes("WEAK") && (
									<Typography
										className={styles.link}
										onClick={(e) => {
											e.stopPropagation();
											selectAllInGroup(groupName);
										}}
									>
										{options
											.filter((option) => option.groupName === groupName)
											.every((option) => filterValues.includes(option.label))
											? "Deselect All"
											: "Select All"}
									</Typography>
								)}
							</Grid>
						</ListSubheader>
						{groupedOptionsMap[groupName].map((option) => (
							<MenuItem
								key={option.label}
								value={option.label}
								onClick={(event) => {
									event.stopPropagation(); // Prevent the click from closing the dropdown
									handleOptionToggle(option.label);
								}}
								className={styles.groupedOption}
							>
								<PrimaryCheckbox
									label=""
									checked={filterValues.includes(option.label)}
									onChange={(e) => e.stopPropagation()} // Prevent checkbox click from closing the dropdown
									size="small"
								/>
								{option.icon && (
									<Grid mr={1} mt={0.85}>
										{option.icon}
									</Grid>
								)}
								<Typography variant="description">{option.label}</Typography>
							</MenuItem>
						))}
					</div>
				))}
			</Select>
		</Grid>
	);
};
