import { csvDownload } from "components/Common/DownloadableLink";
import dayjs from "dayjs";
import { User } from "types";
import { UserActivityType, UserAuthenticationMethod, UserType } from "utilities/constants/enums";

export const downloadFilteredUserList = (
	dispatch: any,
	allUsers: User[],
	userFilters?: [string[], string[], string[]],
) => {
	if (!userFilters) {
		csvDownload(dispatch, allUsers, `AllUsers: ${dayjs().format("YYYY-MM-DD")}.csv`, ";");
		return;
	}

	const [userTypeFilter, userActivityFilter, userAuthenticationMethodFilter] = userFilters;

	const filteredUsers = allUsers.filter((user) =>
		filterUsers(
			user,
			userTypeFilter as UserType[],
			userActivityFilter as UserActivityType[],
			userAuthenticationMethodFilter as UserAuthenticationMethod[],
		),
	);

	csvDownload(dispatch, filteredUsers, `FilteredUsers: ${dayjs().format("YYYY-MM-DD")}.csv`, ";");
};

export const filterUsers = (
	user: User,
	userTypeFilter: UserType[],
	userActivityFilter: UserActivityType[],
	userAuthenticationMethodFilter: UserAuthenticationMethod[], // Added enum type for clarity
) => {
	let userTypeMatch = userTypeFilter.length === 0;
	let userActivityMatch = userActivityFilter.length === 0;
	let userAuthenticationMethodMatch = userAuthenticationMethodFilter.length === 0; // New filter match initialization

	const allFiltersDefault = userTypeMatch && userActivityMatch && userAuthenticationMethodMatch;

	// If all filters are default (accepting any user type, activity, and authentication method), return true
	if (allFiltersDefault) return true;

	// Check if user's type is included in the selected user type filter
	if (!userTypeMatch) {
		userTypeMatch = userTypeFilter.includes(user.userType as UserType);
	}

	// Check if user's sign-in activity status matches any in the user activity filter
	if (!userActivityMatch) {
		userActivityMatch = userActivityFilter.some(
			(activityType) => activityType === user.signInActivityStatus,
		);
	}

	const noneSelectedAndUserHasNoAuthenticationmethods =
		userAuthenticationMethodFilter.includes(UserAuthenticationMethod.None) &&
		user.credentialRegistrationDetails?.methodsRegistered.length === 0;

	// Check if user's authentication method matches any in the user authentication method filter
	if (!userAuthenticationMethodMatch) {
		userAuthenticationMethodMatch =
			user.credentialRegistrationDetails?.methodsRegistered.some((method) =>
				userAuthenticationMethodFilter.includes(method as UserAuthenticationMethod),
			) || noneSelectedAndUserHasNoAuthenticationmethods;
	}

	return userTypeMatch && userActivityMatch && userAuthenticationMethodMatch;
};
